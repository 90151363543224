import React from "react";

const Row = () => {
    return (
      <div className="row m-5 text-center">
        <h3>React Todo</h3>
      </div>
    )
}

export default Row;